import { Suspense } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import routes, { SCREENS } from "./routes";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import ChatModal from "./stories/components/ChatModal/ChatModal";
import { BottomNav } from "./stories/components/Navigation/BottomNav/BottomNav";
import ScrollToTop from "./stories/components/ScrollToTopHoc/ScrollToTopHoc";
import { UnauthenticatedModal } from "./stories/components/UnauthenticatedModal/UnauthenticatedModal";
import { SoundWaveLoader } from "./stories/elements/SoundWaveLoader/SoundWaveLoader";
import { MaintenanceWrapper } from "./stories/screens/MaintenanceScreen/MaintenanceWrapper";
import { toggleLoginModal } from "./store/actions/userInterface";
import { AuthModal } from "./stories/components/Auth/AuthModal";
import { useMediaQueryBreakpoint } from "./hooks/useMediaQuery";

const UiContent = () => {
  const dispatch = useAppDispatch();
  const { showLoginModal } = useAppSelector((state) => state.userInterface);
  const location = useLocation();
  const { isMobile, isTablet } = useMediaQueryBreakpoint();

  return (
    <>
      <ScrollToTop />
      <Suspense
        fallback={
          <div className="container" style={{ margin: "auto" }}>
            <SoundWaveLoader
              width={100}
              height={100}
              styles={{ margin: "auto" }}
            />
          </div>
        }
      >
        <MaintenanceWrapper>
          <Switch>
            {routes.map((route, index) => {
              return (
                <Route
                  exact={route.exact ?? true}
                  path={route.path}
                  component={route.component}
                  key={index}
                />
              );
            })}
          </Switch>
        </MaintenanceWrapper>
      </Suspense>
      {!isMobile && !isTablet && <AuthModal />}
      {/* TODO: Move this modal to the favorites button? and to the livestream page? */}
      <UnauthenticatedModal
        closeModal={() => {
          if (showLoginModal) {
            dispatch(toggleLoginModal());
          }
        }}
        showModal={showLoginModal}
        message={
          location.pathname == SCREENS.LIVESTREAM_SCREEN
            ? "Please sign in to join the stream"
            : "Please sign in to add favorites"
        }
      />
      <ChatModal />
      <BottomNav />
    </>
  );
};

export default UiContent;
