import { ComponentType, FC, lazy } from "react";
import {
  LoggedOutReviewScreen,
  LoggedOutUploadScreen,
  ProjectScreen,
  ProjectsScreen,
  PurchaseOrderScreen,
  RecordingSessionScreen,
  StudioRoomScreen,
  StudioScreen,
} from "../stories/screens";
import { AddSubscriptionScreen } from "../stories/screens/AddSubscriptionScreen/AddSubscriptionScreen";
import { AdminDashboardScreen } from "../stories/screens/AdminDashboardScreen/AdminDashboardScreen";
import { CommunityScreen } from "../stories/screens/CommunityScreen/CommunityScreen";
import { CustomerPortalIndex } from "../stories/screens/CustomerPortalHomeScreen/CustomerPortalIndex";
import { ModularDashboard } from "../stories/screens/Dashboard/ModularDashboard";
import { ExploreScreen } from "../stories/screens/ExploreScreen/ExploreScreen";
import ScheduledProjectOverviewScreen from "../stories/screens/LoggedOutProjectOverview/ScheduledProjectOverviewScreen";
import { MobileCheckoutScreen } from "../stories/screens/MobileCheckoutScreen/MobileCheckoutScreen";
import { MobileProjectReviewScreen } from "../stories/screens/MobileProjectReviewScreen/MobileProjectReviewScreen";
import MusoAuthRedirectScreen from "../stories/screens/MusoAuthRedirectScreen/MusoAuthRedirectScreen";
import MusoSelectionScreen from "../stories/screens/MusoSelectionScreen/MusoSelectionScreen";
import {
  PaginatedVerifiedEntityScreen,
  PaginatedVerifiedStudioRoomsScreen,
} from "../stories/screens/PaginatedVerifiedEntityScreens/PaginatedVerifiedEntityScreen";
import { SignInScreen } from "../stories/screens/SignInScreen/SignInScreen";
import { SignUpScreen } from "../stories/screens/SignUpScreen/SignUpScreen";
import AffirmPaymentRedirectScreen from "../stories/screens/StripePaymentRedirectScreen/AffirmPaymentRedirectScreen";
import CashAppPaymentRedirectScreen from "../stories/screens/StripePaymentRedirectScreen/CashAppPaymentRedirectScreen";
import KlarnaPaymentRedirectScreen from "../stories/screens/StripePaymentRedirectScreen/KlarnaPaymentRedirectScreen";
import { StripeSetupRedirect } from "../stories/screens/StripeSetupRedirect/StripeSetupRedirect";
import { StudioSubscriptionScreen } from "../stories/screens/StudioSubscriptionScreen/StudioSubscriptionScreen";
import { TeamInviteScreen } from "../stories/screens/TeamInviteScreen/TeamInviteScreen";
import TransactionBookingScreen from "../stories/screens/TransactionBookingScreen/TransactionBookingScreen";
import TransactionOverviewScreen from "../stories/screens/TransactionOverviewScreen/TransactionOverviewScreen";
import { VerifyEmailScreen } from "../stories/screens/VerifyEmailScreen/VerifyEmailScreen";
import { BlogScreen } from "../stories/screens/BlogScreen/BlogScreen";
import LoggedOutRecordingSessionDetailsScreen from "../stories/screens/LoggedOutRecordingSessionDetailsScreen/LoggedOutRecordingSessionDetailsScreen";
import { AuthModal } from "../stories/components/Auth/AuthModal";
import LivestreamSelectorScreen from "../stories/screens/LivestreamScreen/LivestreamSelectorScreen";

export interface RouteConfig {
  path: string | string[];
  component: ComponentType | FC;
  exact?: boolean;
}

export enum EXTERNAL_LINKS {
  BLOG = "https://blog.engineears.com/",
  FAQ = "https://community.engineears.com/faq",
  MUSO = "https://muso.ai/",
}

// Be careful not to let these get out of sync with the routes below.
// Having an enum in an enum creates a compilation error
export enum ROUTE_PREFIXES {
  CUSTOMER_PORTAL = "/customer-portal",
  BOOKING = "/booking",
  BOOKINGS = "/bookings",
  FEATURED_TRACK = "/featured-track",
  LISTEN = "/listen",
  MUSO = "/muso",
  PROJECT = "/project",
  PROJECTS = "/projects",
  PROJECT_OVERVIEW = "/project-overview",
  UPLOAD = "/upload",
  SESSION = "/session",
  STUDIO = "/studio",
}

export enum SCREENS {
  PAGINATED_STUDIOS = "/paginated-studios",
  PAGINATED_STUDIOS_ALT = "/for-studios",
  PAGINATED_ENGINEERS = "/paginated-engineers",
  THE_SOUND_OF_TOMORROW = "/thesoundoftomorrow",
  BLOG_SCREEN = "/blog",
  SOUND_OF_TOMORROW = "/soundoftomorrow",
  SEEING_SOUNDS = "/seeingsounds",
  DREAMVILLE = "/dreamville",
  NAMM = "/namm",
  MIXHER = "/mixher",
  SIGN_IN_SCREEN = "/signin",
  SIGN_UP_SCREEN = "/signup",
  HOME_SCREEN = "/",
  SIGNED_OUT_HOME_SCREEN = "/for-all",
  LEGACY_FOR_ARTISTS = "/for-artists",
  LEGACY_FOR_ENGINEERS = "/for-engineers",
  PROFILE_SCREEN = "/:username",
  MESSAGES = "/messages",
  COMMUNITY = "/community",
  DASHBOARD_FAVORITES = "/dashboard/favorites",
  DASHBOARD = "/dashboard",
  NOTIFICATIONS = "/notifications",
  MOBILE_CHECKOUT = "/checkout",
  STUDIO_SCREEN = "/studio/:username",
  STUDIO_ROOM = "/studio/:username/:room_id",
  MOBILE_PROJECT_REVIEW = "/project-review",
  PROJECTS_NO_TAB = "/projects",
  PROJECTS = "/projects/:tab",
  SEARCH = "/search",
  FIND_MY_ENGINEER = "/findmyengineer",
  PROJECT = "/project/:project_id",
  KLARNA_PAYMENT_REDIRECT = "/klarnapayment",
  AFFIRM_PAYMENT_REDIRECT = "/affirmpayment",
  VERIFY_EMAIL = "/verify-email",
  UPLOAD_SCREEN = "/upload/:project_id",
  LOGGED_OUT_REVIEW = "/listen/:project_id",
  LOGGED_OUT_SESSION_DETAILS = "/session-details/:project_id",
  SETUP_STRIPE = "/stripe-setup",
  PASSWORD_RESET = "/reset-password",
  LOG_OUT_ACTION = "/log-out-action",
  ADD_SUBSCRIPTION = "/add-subscription",
  STUDIO_SUBSCRIPTION = "/studio-subscription",
  UPDATE_ACCOUNT_DETAILS = "update-account-details",
  UPDATE_CONTACT_INFO = "update-contact-info",
  LOAD_PREDEFINED_PROJECT = "/purchase-project",
  TRANSACTION_OVERVIEW = "/transaction-overview/:transaction_id",
  RECORDING_BOOKING_CHECKOUT = "/recording-session/checkout",
  RECORDING_BOOKING_SCREEN = "/recording-booking",
  CUSTOMER_PORTAL = "/customer-portal/:tab",
  SCHEDULED_PROJECT_OVERVIEW_SCREEN = "/project-overview/:scheduled_project_id",
  FEATURED_TRACK_REQUEST = "/featured-track/:featured_track_id",
  AANDR_SETUP_SCREEN = "/for-aandr",
  PURCHASE_ORDER = "/purchase-orders",
  DOLBY_REGISTRATION_CHECKPOINT = "/dolby-registration",
  NAMM_REGISTRATION_CHECKPOINT = "/namm-registration",
  USER_FEEDBACK_RATING = "/user-feedback",
  RECORDING_SESSION = "/session/:project_id",
  CASH_APP_REDIRECT = "/cashapppayment",
  SETTINGS_SCREEN = "/settings",
  ONBOARDING_RSVP_SCREEN = "/onboardingrsvp",
  TEAM_INVITE_SCREEN = "/invites",
  ADMIN_DASHBOARD = "/admin-dashboard",
  TRANSACTION_BOOKING_SCREEN = "/booking/:transaction_code",
  BOOKINGS = "/bookings/:tab",
  MUSO_AUTH_REDIRECT_SCREEN = "/musoauth",
  MUSO_SELECTION_SCREEN = "/muso/:tab",
  LIVESTREAM_SCREEN = "/livestream",
  AUTH_SCREEN = "/auth",
}

// This is used in the nav bars to highlight the icon
export const PROJECT_SCREEN_PATHS = [
  SCREENS.PROJECTS_NO_TAB,
  ROUTE_PREFIXES.BOOKINGS,
];

export const SOUND_OF_TOMORROW_LANDING =
  "https://community.engineears.com/soundoftomorrow";
export const DREAMVILLE_LANDING = "https://community.engineears.com/dreamville";
export const NAMM_LANDING = "https://community.engineears.com/namm-2024";
export const MIXHER_LANDING = "https://community.engineears.com/mixher";

const routes: RouteConfig[] = [
  {
    path: [ROUTE_PREFIXES.BOOKINGS, SCREENS.BOOKINGS],
    component: lazy(
      () => import("../stories/screens/BookingsScreen/BookingsScreen"),
    ),
  },
  {
    path: SCREENS.THE_SOUND_OF_TOMORROW,
    component: () => {
      window.location.href = SOUND_OF_TOMORROW_LANDING;
      return null;
    },
  },
  {
    path: SCREENS.SOUND_OF_TOMORROW,
    component: () => {
      window.location.href = SOUND_OF_TOMORROW_LANDING;
      return null;
    },
  },
  {
    path: SCREENS.THE_SOUND_OF_TOMORROW,
    component: () => {
      window.location.href = SOUND_OF_TOMORROW_LANDING;
      return null;
    },
  },
  {
    path: SCREENS.SEEING_SOUNDS,
    component: () => {
      window.location.href = DREAMVILLE_LANDING;
      return null;
    },
  },
  {
    path: SCREENS.DREAMVILLE,
    component: () => {
      window.location.href = DREAMVILLE_LANDING;
      return null;
    },
  },
  {
    path: SCREENS.MIXHER,
    component: () => {
      window.location.href = MIXHER_LANDING;
      return null;
    },
  },

  // Site routes
  {
    path: SCREENS.PAGINATED_STUDIOS,
    component: PaginatedVerifiedStudioRoomsScreen,
  },
  {
    path: SCREENS.PAGINATED_STUDIOS_ALT,
    component: PaginatedVerifiedStudioRoomsScreen,
  },
  {
    path: SCREENS.PAGINATED_ENGINEERS,
    component: PaginatedVerifiedEntityScreen,
  },
  {
    path: SCREENS.DASHBOARD_FAVORITES,
    component: lazy(
      () => import("../stories/screens/FavoritesScreen/FavoritesScreen"),
    ),
  },
  {
    path: SCREENS.RECORDING_SESSION,
    component: RecordingSessionScreen,
  },
  {
    path: SCREENS.AANDR_SETUP_SCREEN,
    component: lazy(
      () => import("../stories/screens/AandRSetupScreen/AandRSetupScreen"),
    ),
  },
  {
    path: SCREENS.DOLBY_REGISTRATION_CHECKPOINT,
    component: lazy(
      () =>
        import(
          "../stories/screens/DolbyCourseRegistrationPage/DolbyCourseRegistrationPage"
        ),
    ),
  },
  {
    path: SCREENS.NAMM_REGISTRATION_CHECKPOINT,
    component: lazy(
      () =>
        import("../stories/screens/NammRegistrationPage/NammRegistrationPage"),
    ),
  },
  {
    path: SCREENS.USER_FEEDBACK_RATING,
    component: lazy(
      () =>
        import(
          "../stories/screens/UserSatisfactionRating/UserSatisfactionRating"
        ),
    ),
  },
  {
    path: SCREENS.PURCHASE_ORDER,
    component: PurchaseOrderScreen,
  },
  {
    path: SCREENS.SCHEDULED_PROJECT_OVERVIEW_SCREEN,
    component: ScheduledProjectOverviewScreen,
  },
  {
    path: SCREENS.MOBILE_CHECKOUT,
    component: MobileCheckoutScreen,
  },
  {
    path: SCREENS.STUDIO_ROOM,
    component: StudioRoomScreen,
  },
  {
    path: SCREENS.STUDIO_SCREEN,
    component: StudioScreen,
  },
  {
    path: SCREENS.LOGGED_OUT_REVIEW,
    component: LoggedOutReviewScreen,
  },
  {
    path: SCREENS.UPLOAD_SCREEN,
    component: LoggedOutUploadScreen,
  },
  {
    path: SCREENS.LOGGED_OUT_SESSION_DETAILS,
    component: LoggedOutRecordingSessionDetailsScreen,
  },
  {
    path: SCREENS.PASSWORD_RESET,
    component: lazy(
      () =>
        import("../stories/screens/ResetPasswordScreen/ResetPasswordScreen"),
    ),
  },
  {
    path: SCREENS.SIGN_IN_SCREEN,
    component: lazy(
      () => import("../stories/screens/LandingScreen/LandingScreen"),
    ),
  },
  {
    path: SCREENS.SIGN_UP_SCREEN,
    component: lazy(
      () => import("../stories/screens/LandingScreen/LandingScreen"),
    ),
  },
  {
    path: SCREENS.SIGNED_OUT_HOME_SCREEN,
    component: lazy(
      () => import("../stories/screens/LandingScreen/LandingScreen"),
    ),
  },
  {
    path: SCREENS.LEGACY_FOR_ARTISTS,
    component: lazy(
      () => import("../stories/screens/LandingScreen/LandingScreen"),
    ),
  },
  {
    path: SCREENS.LEGACY_FOR_ENGINEERS,
    component: lazy(
      () => import("../stories/screens/LandingScreen/LandingScreen"),
    ),
  },
  {
    path: SCREENS.HOME_SCREEN,
    component: ModularDashboard,
  },
  {
    path: SCREENS.DASHBOARD,
    component: ModularDashboard,
  },
  {
    path: SCREENS.CUSTOMER_PORTAL,
    component: CustomerPortalIndex,
  },
  {
    path: SCREENS.KLARNA_PAYMENT_REDIRECT,
    component: KlarnaPaymentRedirectScreen,
  },
  {
    path: SCREENS.CASH_APP_REDIRECT,
    component: CashAppPaymentRedirectScreen,
  },
  {
    path: SCREENS.AFFIRM_PAYMENT_REDIRECT,
    component: AffirmPaymentRedirectScreen,
  },
  {
    path: SCREENS.MUSO_AUTH_REDIRECT_SCREEN,
    component: MusoAuthRedirectScreen,
  },
  {
    path: SCREENS.MUSO_SELECTION_SCREEN,
    component: MusoSelectionScreen,
  },
  {
    path: SCREENS.VERIFY_EMAIL,
    component: VerifyEmailScreen,
  },
  {
    path: SCREENS.SETUP_STRIPE,
    component: StripeSetupRedirect,
  },
  {
    path: SCREENS.NOTIFICATIONS,
    component: lazy(
      () =>
        import(
          "../stories/screens/MobileNotificationsScreen/MobileNotificationsScreen"
        ),
    ),
  },
  {
    path: SCREENS.PROJECTS_NO_TAB,
    component: ProjectsScreen,
  },
  {
    path: SCREENS.PROJECTS,
    component: ProjectsScreen,
    exact: false,
  },
  {
    path: SCREENS.PROJECT,
    component: ProjectScreen,
  },
  {
    path: SCREENS.MESSAGES,
    component: lazy(() => import("../stories/screens/MessagesV2Screen/index")),
  },
  {
    path: SCREENS.COMMUNITY,
    component: CommunityScreen,
    exact: false,
  },
  {
    path: SCREENS.MOBILE_PROJECT_REVIEW,
    component: MobileProjectReviewScreen,
  },
  {
    path: SCREENS.FIND_MY_ENGINEER,
    component: lazy(
      () =>
        import("../stories/screens/FindMyEngineerScreen/FindMyEngineerScreen"),
    ),
  },
  {
    path: SCREENS.SEARCH,
    component: ExploreScreen,
    exact: false,
  },
  {
    path: SCREENS.ADD_SUBSCRIPTION,
    component: AddSubscriptionScreen,
  },
  {
    path: SCREENS.STUDIO_SUBSCRIPTION,
    component: StudioSubscriptionScreen,
  },
  {
    path: SCREENS.LOAD_PREDEFINED_PROJECT,
    component: lazy(
      () =>
        import(
          "../stories/screens/LoadPredefinedProjectScreen/LoadPredefinedProject"
        ),
    ),
  },
  {
    path: SCREENS.SETTINGS_SCREEN,
    component: lazy(
      () => import("../stories/screens/SettingsScreen/SettingsScreen"),
    ),
  },
  {
    path: SCREENS.TRANSACTION_OVERVIEW,
    component: TransactionOverviewScreen,
  },
  {
    path: SCREENS.ONBOARDING_RSVP_SCREEN,
    component: lazy(
      () =>
        import("../stories/screens/OnboardingRSVPScreen/OnboardingRSVPScreen"),
    ),
  },
  {
    path: SCREENS.TEAM_INVITE_SCREEN,
    component: TeamInviteScreen,
  },
  {
    path: SCREENS.ADMIN_DASHBOARD,
    component: AdminDashboardScreen,
  },
  {
    path: SCREENS.BLOG_SCREEN,
    component: BlogScreen,
  },
  {
    path: SCREENS.TRANSACTION_BOOKING_SCREEN,
    component: TransactionBookingScreen,
  },
  {
    path: SCREENS.LIVESTREAM_SCREEN,
    component: LivestreamSelectorScreen,
  },
  {
    path: SCREENS.FEATURED_TRACK_REQUEST,
    component: lazy(
      () =>
        import("../stories/screens/FeaturedTrackReview/FeaturedTrackReview"),
    ),
  },
  {
    path: SCREENS.AUTH_SCREEN,
    component: AuthModal,
  },
  // Profile screen needs to go last, as its regex captures all other routes before it
  {
    path: SCREENS.PROFILE_SCREEN,
    component: lazy(
      () => import("../stories/screens/ProfileScreen/ProfileScreen"),
    ),
  },
];

export default routes;
