import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../../../../store/hooks";
import { loadUser } from "../../../../store/actions/accountInfo";

const useLoadUser = () => {
  const dispatch = useAppDispatch();
  const [initialAppLoad, setInitLoad] = useState(true);
  // Load the logged-in user information on initial app load.
  const initialLoadUser = useCallback(() => {
    setInitLoad(true);
    dispatch(loadUser())
      .unwrap()
      .then(() => {
        setInitLoad(false);
      })
      .catch(() => {
        setInitLoad(false);
      });
  }, [dispatch]);

  useEffect(() => {
    if (!initialAppLoad) {
      return;
    }
    initialLoadUser();
  }, [initialAppLoad, initialLoadUser]);

  return initialAppLoad;
};

export default useLoadUser;
