import { makeBackendGetCallWithJsonResponse } from "../../store/utils/fetch";
import { FETCH_TRACK, FETCH_TRACK_SNIPPET } from "../../store/utils/routes";

export interface DownloadTrackByPathParams {
  isSnippet?: boolean;
  params: string;
}

export const downloadTrackByPath = async ({
  isSnippet = false,
  params,
}: DownloadTrackByPathParams) => {
  const response = await makeBackendGetCallWithJsonResponse<{ url: string }>(
    isSnippet ? FETCH_TRACK_SNIPPET : FETCH_TRACK,
    params,
  );

  if (response.success) {
    return response.resultJson;
  }
  return Promise.reject(response.resultJson);
};

export default downloadTrackByPath;
