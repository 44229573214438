import { Box } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { AuthFlows } from "../../../constants/authSteps";
import { useAppSelector } from "../../../store/hooks";
import { getDisplayableNameForUser } from "../../../store/utils/entityUtils";
import {
  getDashboardRoute,
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../store/utils/routeGetters";
import { Button } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { authModalOpenAtom, currentFlowAtom } from "./atoms";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";

export const AccountCreateComplete = () => {
  const flow = useAtomValue(currentFlowAtom);
  const [, setAuthModalOpen] = useAtom(authModalOpenAtom);
  const { user, userStudios } = useAppSelector((state) => state.accountInfo);
  const username = getDisplayableNameForUser(user);

  const redirectPath = useMemo(() => {
    if (!user) return getDashboardRoute();

    if (flow === AuthFlows.STUDIO_OWNER) {
      return getStudioScreenRoute(userStudios[0]?.username);
    } else if (flow === AuthFlows.AANDR) {
      return getDashboardRoute();
    } else {
      return {
        pathname: getProfileScreenRoute(user.username),
        state: { showTutorial: true },
      };
    }
  }, [flow, user, userStudios]);

  if (!user)
    return (
      <Box>
        <Text variant={TextStyleVariant.H5}>
          Your profile has been created!
        </Text>
      </Box>
    );

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        gap: "32px",
        padding: "40px",
      }}
    >
      <Text variant={TextStyleVariant.H5}>
        Congratulations {username}, your profile has been created!
      </Text>
      <Link
        style={{ textDecoration: "none" }}
        onClick={() => {
          emitAnalyticsTrackingEvent("auth_account_create_complete", {});
          setAuthModalOpen(false);
        }}
        to={redirectPath}
      >
        <Button>Go to profile</Button>
      </Link>
    </Box>
  );
};
