import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  exceptionCodeErrorStringMap,
  STUDIO_PROFILE_FOUND,
  TRANSITION_NOT_ALLOWED,
  UNKNOWN_ERROR,
} from "../../../../store/models/exceptions";
import { clearErrors } from "../../../../store/actions/errorStore";

const useErrorToasts = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const errors = useAppSelector((state) => state.errorStore.errors);
  useEffect(() => {
    errors.forEach((error) => {
      if (error === undefined) return;
      if ("code" in error && error.code === STUDIO_PROFILE_FOUND) {
        if (window.location.pathname.includes("/studio/")) return;
        history.push("/studio" + window.location.pathname);
      }
      if ("code" in error && error.code === UNKNOWN_ERROR) {
        if (error.non_field_errors && error.non_field_errors.length > 0) {
          toast.error(error.non_field_errors[0]);
        }
      } else if (
        "code" in error &&
        error.code === TRANSITION_NOT_ALLOWED &&
        "detail" in error &&
        error.detail &&
        error.detail.length > 0
      ) {
        toast.error(error.detail);
      } else {
        if (exceptionCodeErrorStringMap.get(error.code)?.length !== 0) {
          toast.error(exceptionCodeErrorStringMap.get(error.code));
        }
      }
    });
    dispatch(clearErrors());
  }, [errors, dispatch, history]);
};

export default useErrorToasts;
