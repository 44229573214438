import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, IconButton, styled } from "@mui/material";
import { AuthSteps } from "../../../constants/authSteps";
import { Breakpoint } from "../../../utils/breakpoints";
import { Button } from "../../core-ui/components/Button/Button";
import { stepToImage } from "./AuthBannerInfoWrapper";
import { ReactMultiEmail } from "react-multi-email";
import { CheckBox } from "../../core-ui/components/CheckBox/CheckBox";

export const CloseButton = styled(IconButton)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  border-radius: var(--border-radius-sm);
  background-color: transparent;

  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.customColor.midgroundColor};
  }
`;

export const AuthModalCheckbox = styled(CheckBox)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const AuthModalContainer = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const AuthBannerInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 560px;
  overflow: hidden;
  background: black;
`;

export const AuthBanner = styled(Box)<{
  $step: AuthSteps;
}>`
  background-image: ${({ $step }) =>
    $step in stepToImage ? `url(${stepToImage[$step]})` : "unset"};
  background-size: cover;
  background-position: center;
  mask: linear-gradient(black 60%, transparent);
  height: 259px;
`;

export const AuthBannerTextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 12px;
`;

export const AuthNavigationContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 340px;
  height: 500px;
  padding: 20px 40px 40px 40px;
  overflow: hidden;
  justify-content: space-between;
  align-items: flex-start;

  @media ${Breakpoint.Mobile}, ${Breakpoint.Tablet} {
    padding: 20px 20px 40px 20px;
    width: calc(100% - 40px);
  }
`;

export const MainContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
  overflow: hidden;
`;

export const SignUpButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  gap: 16px;
  align-self: center;
  width: 100%;
`;

export const SignUpSocialButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const SignUpSocialButton = styled(Button)`
  gap: 8px;
  min-width: 162px;
  max-height: 44px;
`;

export const AppleLogo = styled(FontAwesomeIcon)`
  font-size: 20px;
`;

export const FacebookLogo = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${({ theme }) => theme.palette.standardColor.Blue[500]};
`;

export const SignUpFooter = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const ProfileTypeCardContainer = styled(Card)<{
  $isDisabled: boolean;
  $isSelected: boolean;
}>`
  display: flex;
  min-height: 90px;
  min-width: 304px;
  padding: 16px;
  align-items: center;
  border-radius: 16px;
  border: 1px solid
    ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.palette.success.main
        : theme.palette.customColor.darkerMidgroundColor};
  cursor: ${({ $isDisabled }) => ($isDisabled ? "not-allowed" : "pointer")};
  box-shadow: none;
`;

export const ProfileTypeCardInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 266px;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;
`;

export const ProfileTypeCardTitle = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ProfileTypeCardTitlePill = styled(Box)<{ $isSelected: boolean }>`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  background: ${({ theme, $isSelected }) =>
    $isSelected
      ? theme.palette.customColor.successFadedColor
      : theme.palette.customColor.midgroundColor};
`;

export const ProfileTypeCardCheck = styled(FontAwesomeIcon)<{
  $isSelected: boolean;
}>`
  --fa-primary-color: ${({ theme, $isSelected }) =>
    $isSelected
      ? theme.palette.success.main
      : theme.palette.customColor.midgroundColor};
  --fa-secondary-color: ${({ theme, $isSelected }) =>
    $isSelected
      ? theme.palette.customColor.successFadedColor
      : theme.palette.customColor.midgroundColor};
  height: 41px;
  width: 41px;
`;

export const ProfileTypeContainer = styled(Box)`
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
  max-height: 750px;

  @media ${Breakpoint.Mobile}, ${Breakpoint.Tablet} {
    max-height: unset;
  }
`;

export const ProfileTypeHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  text-align: center;
`;

export const ProfileTypeBody = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-self: stretch;
  min-height: 460px;
  max-height: 750px;
  overflow: auto;

  @media (max-width: 1195px) {
    flex-direction: column;
  }

  @media ${Breakpoint.Mobile}, ${Breakpoint.Tablet} {
    overflow: unset;
    max-height: unset;
  }
`;

export const ProfileTypeOptionsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media ${Breakpoint.Mobile}, ${Breakpoint.Tablet} {
    flex-direction: column;
  }
`;

export const ProfileTypeOptionsNoteContainer = styled(Box)<{ $show: boolean }>`
  display: flex;
  min-height: 40px;
  border-radius: 8px;
  padding: 12px;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  background-color: ${({ theme, $show }) =>
    $show
      ? theme.palette.standardColor.BoxyYellow[100]
      : theme.palette.background.paper};

  @media ${Breakpoint.Mobile}, ${Breakpoint.Tablet} {
    background-color: ${({ theme, $show }) =>
      $show
        ? theme.palette.standardColor.BoxyYellow[100]
        : theme.palette.background.default};
  }
`;

export const ProfileTypeOptionsList = styled(Box)`
  display: flex;
  width: 336px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const ProfileTypeOptionListSeparator = styled(Box)`
  border-left: 1px solid ${({ theme }) => theme.palette.text.secondary};
  height: 100%;
`;

export const LabelSelectionFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  max-height: 800px;
  padding: 40px;

  @media ${Breakpoint.Mobile}, ${Breakpoint.Tablet} {
    max-height: unset;
    width: calc(100% - 80px);
  }
`;

export const LabelSelectionFormBody = styled(Box)`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  overflow-y: scroll;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media ${Breakpoint.Mobile}, ${Breakpoint.Tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 481px) and (max-width: 1050px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const LabelItemContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const LabelItemPhoto = styled(Box)<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid
    ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.palette.primary.main
        : theme.palette.customColor.buttonColorHover};
  background: ${({ theme }) => theme.palette.standardColor.White[0]};
  height: 200px;
  width: 200px;

  @media ${Breakpoint.Mobile}, ${Breakpoint.Tablet} {
    height: 150px;
    width: 150px;
  }
`;

export const BuildTeamEmailInputContainer = styled(ReactMultiEmail)`
  &:not([data-tag]) {
    width: calc(100% - 38px);
    min-height: 120px;
    text-align: left;
    padding: 14px 16px;
    background: ${({ theme }) => theme.palette.background.paper};
    border: 2px solid
      ${({ theme }) => theme.palette.customColor.buttonColorHover};
    border-radius: 8px;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  &.react-multi-email > input {
    width: auto;
    outline: none;
    border: 0 none;
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    padding: 0.4em 0.1em;
    background-color: ${({ theme }) => theme.palette.background.paper};
    color: ${({ theme }) => theme.palette.text.primary};
  }
  &.react-multi-email [data-tag] {
    line-height: 1;
    vertical-align: baseline;
    background-color: ${({ theme }) =>
      theme.palette.customColor.darkerMidgroundColor};
    color: ${({ theme }) => theme.palette.text.primary};
    padding: 8px;
    border-radius: 8px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    margin: 4px;
  }
`;
