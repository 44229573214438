import { useGoogleLogin } from "@react-oauth/google";
import { useSetAtom } from "jotai";
import { useState } from "react";
import { toast } from "react-toastify";
import { AuthFlows } from "../../constants/authSteps";
import { startGoogleLogin } from "../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { currentFlowAtom } from "../../stories/components/Auth/atoms";
import { useCheckUserLoginFlow } from "./useCheckUserLoginFlow";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";

const useGoogleAuth = () => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const dispatch = useAppDispatch();
  const setFlow = useSetAtom(currentFlowAtom);
  const [isGoogleLoginOpen, setIsGoogleLoginOpen] = useState(false);
  const { checkUserAndSetFlow } = useCheckUserLoginFlow();

  const handleGoogleLogin = useGoogleLogin({
    onError: () => {
      toast.error(
        "Something went wrong. Please try again or reach out to customer support.",
      );
      setIsGoogleLoginOpen(false);
    },
    onNonOAuthError: () => {
      setIsGoogleLoginOpen(false);
    },
    onSuccess: async (codeResponse) => {
      const { code } = codeResponse;
      try {
        const result = await dispatch(
          startGoogleLogin({ authorization_code: code }),
        ).unwrap();
        emitAnalyticsTrackingEvent("login_google", {}, result?.user?.id);
        if (result.created) {
          setFlow(AuthFlows.STANDARD_SIGNUP);
        } else {
          checkUserAndSetFlow(result.user);
        }
        setIsGoogleLoginOpen(false);
      } catch {
        /* empty */
        setIsGoogleLoginOpen(false);
      }
    },
    flow: "auth-code",
  });

  const handleGoogleClick = () => {
    emitAnalyticsTrackingEvent("auth_click_on_google_login", {}, user?.id);
    handleGoogleLogin();
    setIsGoogleLoginOpen(true);
  };
  return { handleGoogleClick, isGoogleLoginOpen };
};

export default useGoogleAuth;
