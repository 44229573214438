import { Box } from "@mui/material";
import { useSetAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthFlows } from "../../../constants/authSteps";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import { useIsUMGUser } from "../../../hooks/useIsAandR";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import {
  DISCIPLINE_TYPE,
  getDisciplineString,
  getPrimaryDiscipline,
  getUserDisciplines,
} from "../../../hooks/user";
import {
  updateUserAAndR,
  updateUserAdmin,
  updateUserArtist,
  updateUserEngineer,
  updateUserListener,
  updateUserOther,
  updateUserProducer,
  updateUserStudioManager,
} from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import UMG_Logo_Black from "../../assets/UMG Logo_Black.svg";
import { Button } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import {
  DEFAULT_TAB_OVERLAY_CLASS,
  useBottomTabBarOverlayView,
} from "../Navigation/BottomNav/useBottomTabBarOverlayView";
import { currentFlowAtom } from "./atoms";
import {
  ProfileTypeBody,
  ProfileTypeContainer,
  ProfileTypeHeader,
  ProfileTypeOptionListSeparator,
  ProfileTypeOptionsContainer,
  ProfileTypeOptionsList,
  ProfileTypeOptionsNoteContainer,
} from "./AuthModal.styles";
import { AuthNavigation } from "./AuthNavigation";
import { ProfileTypeCard } from "./ProfileTypeCard";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { showBottomAndTopNavAtom } from "../../../atoms/navAtoms";

export const ProfileType = () => {
  const dispatch = useAppDispatch();
  const setFlow = useSetAtom(currentFlowAtom);
  const authNavigation = useAuthNavigationSteps();
  const { nextStep } = authNavigation();
  const user = useAppSelector((state) => state.accountInfo.user);
  const isUMGUser = useIsUMGUser(user);
  const userDisciplines = getUserDisciplines(user);
  const userPrimaryDiscipline = getPrimaryDiscipline(user);
  const [initialProfileTypes] = useState<DISCIPLINE_TYPE[]>(userDisciplines);
  const [selectedProfileTypes, setSelectedProfileTypes] =
    useState<DISCIPLINE_TYPE[]>(userDisciplines);
  const [primaryProfileType, setPrimaryProfileType] = useState<DISCIPLINE_TYPE>(
    userPrimaryDiscipline,
  );
  const [otherTypeDescription, setOtherTypeDescription] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shouldProceed, setShouldProceed] = useState<boolean>(false);
  const { isDesktop } = useMediaQueryBreakpoint();

  const disciplineTypeToAccountApiMap = new Map<
    DISCIPLINE_TYPE,
    | typeof updateUserEngineer
    | typeof updateUserArtist
    | typeof updateUserProducer
    | typeof updateUserAAndR
    | typeof updateUserStudioManager
    | typeof updateUserOther
    | typeof updateUserListener
    | typeof updateUserAdmin
  >([
    [DISCIPLINE_TYPE.ENGINEER, updateUserEngineer],
    [DISCIPLINE_TYPE.ARTIST, updateUserArtist],
    [DISCIPLINE_TYPE.PRODUCER, updateUserProducer],
    [DISCIPLINE_TYPE.A_AND_R, updateUserAAndR],
    [DISCIPLINE_TYPE.STUDIO_MANAGER, updateUserStudioManager],
    [DISCIPLINE_TYPE.OTHER, updateUserOther],
    [DISCIPLINE_TYPE.LISTENER, updateUserListener],
    [DISCIPLINE_TYPE.ADMIN, updateUserAdmin],
  ]);

  const ProfileTypeDescriptions: Record<DISCIPLINE_TYPE, string> = {
    [DISCIPLINE_TYPE.NO_TYPE]: "",
    [DISCIPLINE_TYPE.ARTIST]:
      "I want to record, mix, master, and/or release my songs.",
    [DISCIPLINE_TYPE.STUDIO_MANAGER]: "I want to offer hourly studio bookings.",
    [DISCIPLINE_TYPE.ENGINEER]:
      "I want to get paid to record, mix, and/or master songs.",
    [DISCIPLINE_TYPE.PRODUCER]:
      "I want to sell my production and get paid for collaborations.",
    [DISCIPLINE_TYPE.ADMIN]:
      "I want to manage budgets and approvals for team projects.",
    [DISCIPLINE_TYPE.A_AND_R]:
      "I want to manage the completion of my artists’ songs.",
    [DISCIPLINE_TYPE.LISTENER]: "I want to discover and support new music.",
    [DISCIPLINE_TYPE.OTHER]: "I signed up for a different reason.",
  };

  const firstRowOfOptions: DISCIPLINE_TYPE[] = [
    DISCIPLINE_TYPE.ARTIST,
    DISCIPLINE_TYPE.STUDIO_MANAGER,
    DISCIPLINE_TYPE.LISTENER,
  ];

  const secondRowOfOptions: DISCIPLINE_TYPE[] = [
    DISCIPLINE_TYPE.ENGINEER,
    DISCIPLINE_TYPE.PRODUCER,
    DISCIPLINE_TYPE.OTHER,
  ];

  const thirdRowOfOptions: DISCIPLINE_TYPE[] = [
    DISCIPLINE_TYPE.ADMIN,
    DISCIPLINE_TYPE.A_AND_R,
  ];

  useEffect(() => {
    if (shouldProceed) {
      nextStep();
      setShouldProceed(false);
    }
  }, [shouldProceed, nextStep]);

  const handleNextButtonClick = async () => {
    setIsLoading(true);
    try {
      const addedProfileTypes = selectedProfileTypes.filter(
        (type) => !initialProfileTypes.includes(type),
      );
      const removedProfileTypes = initialProfileTypes.filter(
        (type) => !selectedProfileTypes.includes(type),
      );
      const isPrimaryInAddedOrRemoved =
        addedProfileTypes.includes(primaryProfileType) ||
        removedProfileTypes.includes(primaryProfileType);

      for (const profileType of [
        ...addedProfileTypes,
        ...removedProfileTypes,
      ]) {
        const updateUserThunk = disciplineTypeToAccountApiMap.get(profileType);
        if (!updateUserThunk) continue;

        const isAdding = addedProfileTypes.includes(profileType);
        const isPrimary = profileType === primaryProfileType;
        const isDeleted = !isAdding;

        await dispatch(
          updateUserThunk({
            ...(profileType !== DISCIPLINE_TYPE.ADMIN && {
              is_primary_type: isPrimary,
            }),
            deleted: isDeleted,
            ...(profileType === DISCIPLINE_TYPE.OTHER && {
              account_type_description: otherTypeDescription,
            }),
          }),
        ).unwrap();
      }

      if (
        !isPrimaryInAddedOrRemoved &&
        primaryProfileType !== DISCIPLINE_TYPE.NO_TYPE
      ) {
        const updateUserThunk =
          disciplineTypeToAccountApiMap.get(primaryProfileType);
        if (!updateUserThunk) {
          // do nothing
        } else {
          await dispatch(
            updateUserThunk({
              is_primary_type: true,
              deleted: false,
              ...(primaryProfileType === DISCIPLINE_TYPE.OTHER && {
                account_type_description: otherTypeDescription,
              }),
            }),
          ).unwrap();
        }
      }

      if (primaryProfileType === DISCIPLINE_TYPE.ARTIST) {
        emitAnalyticsTrackingEvent("register_as_artist", {
          user_id: user?.id,
          username: user?.username,
          email: user?.email,
        });
      }

      if (primaryProfileType === DISCIPLINE_TYPE.ENGINEER) {
        emitAnalyticsTrackingEvent("register_as_engineer", {
          user_id: user?.id,
          username: user?.username,
          email: user?.email,
        });
      }

      emitAnalyticsTrackingEvent(
        "auth_submit_profile_types",
        {
          primary_profile_type: primaryProfileType,
          selected_profile_types: selectedProfileTypes,
          other_profile_type_description: otherTypeDescription,
        },
        user?.id,
      );

      if (selectedProfileTypes.includes(DISCIPLINE_TYPE.STUDIO_MANAGER)) {
        setFlow(AuthFlows.STUDIO_OWNER);
        setShouldProceed(true);
      } else if (isUMGUser) {
        setFlow(AuthFlows.AANDR);
        setShouldProceed(true);
      } else {
        setFlow(AuthFlows.STANDARD_SIGNUP);
        setShouldProceed(true);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again or contact support.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleProfileTypeClick = useCallback(
    (profileType: DISCIPLINE_TYPE) => {
      setSelectedProfileTypes((prevSelected) => {
        emitAnalyticsTrackingEvent("auth_profile_type_clicked", {
          profile_type: profileType,
        });
        let updatedSelected: DISCIPLINE_TYPE[] = [];
        if (profileType === DISCIPLINE_TYPE.STUDIO_MANAGER) {
          if (prevSelected.includes(DISCIPLINE_TYPE.STUDIO_MANAGER)) {
            // If STUDIO_MANAGER is already selected, deselect it
            updatedSelected = [];
          } else {
            // If selecting STUDIO_MANAGER, it should be the only one selected
            updatedSelected = [DISCIPLINE_TYPE.STUDIO_MANAGER];
          }
        } else {
          if (prevSelected.includes(DISCIPLINE_TYPE.STUDIO_MANAGER)) {
            // If STUDIO_MANAGER is already selected, do not allow other selections
            return prevSelected;
          } else if (prevSelected.includes(profileType)) {
            updatedSelected = prevSelected.filter(
              (type) => type !== profileType,
            );
          } else {
            updatedSelected = [...prevSelected, profileType];
          }
        }
        // Update primary profile type
        if (updatedSelected.length === 1) {
          setPrimaryProfileType(updatedSelected[0]);
        } else if (updatedSelected.length === 0) {
          setPrimaryProfileType(DISCIPLINE_TYPE.NO_TYPE);
        } else if (primaryProfileType === profileType) {
          setPrimaryProfileType(updatedSelected[0]);
        }
        return updatedSelected;
      });
    },
    [primaryProfileType, selectedProfileTypes],
  );

  const createProfileTypeCard = (
    profileType: DISCIPLINE_TYPE,
    primaryProfileType: DISCIPLINE_TYPE | null,
    selectedProfileTypes: DISCIPLINE_TYPE[],
    isAandR: boolean,
    handleProfileTypeClick: (profileType: DISCIPLINE_TYPE) => void,
  ) => (
    <ProfileTypeCard
      key={profileType}
      profileType={getDisciplineString(profileType)}
      description={ProfileTypeDescriptions[profileType]}
      isPrimary={primaryProfileType === profileType}
      isSelected={selectedProfileTypes.includes(profileType)}
      isDisabled={
        isAandR ||
        (selectedProfileTypes.includes(DISCIPLINE_TYPE.STUDIO_MANAGER) &&
          profileType !== DISCIPLINE_TYPE.STUDIO_MANAGER) ||
        (selectedProfileTypes.length > 0 &&
          !selectedProfileTypes.includes(DISCIPLINE_TYPE.STUDIO_MANAGER) &&
          profileType === DISCIPLINE_TYPE.STUDIO_MANAGER)
      }
      onClick={() => handleProfileTypeClick(profileType)}
      isOtherType={profileType === DISCIPLINE_TYPE.OTHER}
      setOtherTypeDescription={setOtherTypeDescription}
      otherTypeDescription={otherTypeDescription}
      alreadySelected={selectedProfileTypes.includes(profileType)}
    />
  );

  const nextMobileStickyButton = useBottomTabBarOverlayView(
    !isDesktop,
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        flexWrap: "nowrap",
      }}
    >
      <Button
        style={{ borderRadius: "0px" }}
        onClick={handleNextButtonClick}
        disabled={!selectedProfileTypes.length || isLoading}
        loading={isLoading}
        fullWidth
      >
        Next
      </Button>
    </Box>,
    DEFAULT_TAB_OVERLAY_CLASS,
  );

  return (
    <ProfileTypeContainer>
      <AuthNavigation />
      <ProfileTypeHeader>
        <Text variant={TextStyleVariant.H3}>Choose your profile</Text>
        <Text variant={TextStyleVariant.P1}>
          Select all profile types that fit your needs below.
        </Text>
      </ProfileTypeHeader>
      <ProfileTypeBody>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <ProfileTypeOptionsContainer>
            <ProfileTypeOptionsList>
              {firstRowOfOptions.map((type) =>
                createProfileTypeCard(
                  type,
                  primaryProfileType,
                  selectedProfileTypes,
                  isUMGUser,
                  handleProfileTypeClick,
                ),
              )}
            </ProfileTypeOptionsList>
            <ProfileTypeOptionsList>
              {secondRowOfOptions.map((type) =>
                createProfileTypeCard(
                  type,
                  primaryProfileType,
                  selectedProfileTypes,
                  isUMGUser,
                  handleProfileTypeClick,
                ),
              )}
            </ProfileTypeOptionsList>
          </ProfileTypeOptionsContainer>
          <ProfileTypeOptionsNoteContainer
            $show={selectedProfileTypes.length > 0 && !isUMGUser}
          >
            {selectedProfileTypes.length > 0 && !isUMGUser && (
              <Text
                variant={TextStyleVariant.P2}
                color={TextColor.PRIMARY_NO_DARK_THEME}
                style={{
                  textAlign: "center",
                  maxWidth: "450px",
                  fontWeight: "600",
                }}
              >
                {selectedProfileTypes.includes(DISCIPLINE_TYPE.STUDIO_MANAGER)
                  ? "Note: if you would like to add an artist, engineer, or listener profile type, please do so in the account settings after signing up."
                  : "Note: if you would like to add a studio owner profile type, please do so in the account settings after signing up."}
              </Text>
            )}
          </ProfileTypeOptionsNoteContainer>
        </Box>
        <ProfileTypeOptionListSeparator />
        <ProfileTypeOptionsList style={!isDesktop ? { marginTop: "15px" } : {}}>
          <img src={UMG_Logo_Black} width={350} height={120} />
          {thirdRowOfOptions.map((type) =>
            createProfileTypeCard(
              type,
              primaryProfileType,
              selectedProfileTypes,
              !isUMGUser,
              handleProfileTypeClick,
            ),
          )}
          <Text variant={TextStyleVariant.P2} color={TextColor.TERTIARY}>
            *UMG Single Sign-on (SSO) required.
          </Text>
        </ProfileTypeOptionsList>
      </ProfileTypeBody>
      {!isDesktop ? (
        selectedProfileTypes.length > 0 ? (
          nextMobileStickyButton
        ) : null
      ) : (
        <Button
          onClick={handleNextButtonClick}
          disabled={!selectedProfileTypes.length || isLoading}
          loading={isLoading}
        >
          Next
        </Button>
      )}
    </ProfileTypeContainer>
  );
};
